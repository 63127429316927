<template>
  <div>
    <div class="orderboxs">
      <div class="management" ref="scrollDiv">
        <div class="management_container d-flex">
          <el-card class="management_basics">
            <div class="management_basics_body" slot="header">
              <div class="service_title">客服设置</div>
            </div>
            <div class="service_container">
              <div class="service_list">
                <div
                  class="custom_single"
                  v-for="(item, index) in serviceList"
                  :key="index"
                >
                  <div class="handle">
                    <img
                      v-if="index < serviceList.length - 1"
                      src="../../assets/diy/order-descending.png"
                      @click.stop="customerDown(index)"
                      alt=""
                    />
                    <img
                      v-if="index > 0"
                      src="../../assets/diy/order-ascending.png"
                      alt=""
                      @click.stop="customerUp(index)"
                    />
                    <img
                      src="../../assets/diy/del.png"
                      alt=""
                      @click.stop="customerDel(index)"
                    />
                  </div>
                  <div class="management_basics_form_item d-flex">
                    <div class="management_basics_form_label">客服类型:</div>
                    <div class="custom_content d-flex align-items-center">
                      <div
                        class="typeSingle"
                        v-for="(i, b) in typeList"
                        :key="b"
                        :class="
                          item['customer.service.type'] == i.id ? 'active' : ''
                        "
                        @click.stop="typeTab(index, i.id)"
                      >
                        <div>{{ i.name }}</div>
                        <div :class="['type_icon']">
                          <img
                            v-if="i.id == 1"
                            src="../../assets/diy/qrcode.svg"
                            alt=""
                          />
                          <img
                            src="../../assets/diy/phone.svg"
                            v-if="i.id == 2"
                          />
                          <img
                            src="../../assets/diy/link.svg"
                            v-if="i.id == 3"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="management_basics_form_item d-flex">
                    <div class="management_basics_form_label">图标:</div>
                    <div class="icon_content">
                      <div
                        class="icon_add"
                        @click.stop="cust_icon(index, 'customer.service.icon')"
                        v-if="!item['customer.service.icon']"
                      >
                        <span>+</span>
                      </div>
                      <div
                        class="icon_img"
                        v-else
                        @click.stop="cust_icon(index, 'customer.service.icon')"
                      >
                        <img :src="item['customer.service.icon']" alt="" />
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="icon_toast">图片尺寸建议100*100</div>
                        <div
                          class="icon_moren"
                          @click.stop="tacitly_approve(index)"
                        >
                          使用默认图标
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="management_basics_form_item d-flex">
                    <div class="management_basics_form_label">标题</div>
                    <div class="title_content">
                      <el-input
                        v-model="item['customer.service.title']"
                      ></el-input>
                    </div>
                  </div>
                  <div
                    class="management_basics_form_item d-flex"
                    v-if="item['customer.service.type'] == 1"
                  >
                    <div class="management_basics_form_label">二维码:</div>
                    <div class="icon_content">
                      <div
                        class="icon_add"
                        @click.stop="
                          cust_icon(index, 'customer.service.qr_code')
                        "
                        v-if="!item['customer.service.qr_code']"
                      >
                        <span>+</span>
                      </div>
                      <div
                        class="icon_img"
                        v-else
                        @click.stop="
                          cust_icon(index, 'customer.service.qr_code')
                        "
                      >
                        <img :src="item['customer.service.qr_code']" alt="" />
                      </div>
                      <div class="icon_toast">二维码图片尺寸建议200*200</div>
                    </div>
                  </div>
                  <div
                    class="management_basics_form_item d-flex"
                    v-if="item['customer.service.type'] == 2"
                  >
                    <div class="management_basics_form_label">电话号码:</div>
                    <div class="title_content">
                      <el-input
                        v-model="item['customer.service.phone']"
                      ></el-input>
                    </div>
                  </div>
                  <div
                    class="management_basics_form_item d-flex"
                    v-if="item['customer.service.type'] == 3"
                  >
                    <div class="management_basics_form_label">外部链接:</div>
                    <div class="title_content">
                      <el-input
                        v-model="item['customer.service.outside_url']"
                        prefix-icon="el-icon-link"
                      ></el-input>
                    </div>
                  </div>
                  <div class="management_basics_form_item d-flex">
                    <div class="management_basics_form_label">是否显示:</div>
                    <div class="show_content">
                      <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        v-model="item['customer.service.show']"
                        active-color="#000D9A"
                        :width="44"
                        style="zoom: 0.8"
                      ></el-switch>
                    </div>
                  </div>
                </div>
                <div class="addcustom" @click.stop="newAdd()">
                  <div><span>+</span></div>
                  新增
                </div>
              </div>
            </div>
          </el-card>
          <template>
            <div class="service_wrap d-flex">
              <el-card class="service_show">
                <div class="management_basics_body" slot="header">
                  <p class="service_title">预览</p>
                </div>
                <div class="service_show_body">
                  <div class="preview-customer">
                    <div
                      class=""
                      v-for="(item, index) in serviceList"
                      :key="index"
                    >
                      <template v-if="item['customer.service.show'] == 1">
                        <div class="preview_single">
                          <img :src="item['customer.service.icon']" alt="" />
                          <p>{{ item["customer.service.title"] }}</p>
                        </div>
                      </template>
                    </div>
                    <div class="preview_single">
                      <div class="preview_top">
                        <img src="../../assets/diy/top.svg" alt="" />
                      </div>
                      <p>回到顶部</p>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </template>
        </div>
        <diy-footer-button v-if="hasPerm(['pc.customer.update'])">
          <el-button
            type="primary"
            @click.stop="preserve"
            :loading="caijiLoading"
            >保存</el-button
          >
        </diy-footer-button>
      </div>
    </div>

    <linkUrl ref="selectLink" @change="linkGet"></linkUrl>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_multiple: 0,
      serviceList: [],
      typeList: [
        {
          id: 1,
          name: "二维码类型",
        },
        {
          id: 2,
          name: "电话类型",
        },
        {
          id: 3,
          name: "链接类型",
        },
      ],
      customerIndex: 0,
      customerType: "",
      caijiLoading: false,
    };
  },
  computed: {
    common_info: {
      get() {
        let stateuser = {
          "pc.config.list": {},
          "cat.list": [],
          "goods.status.list": [],
          "default.config.list": {},
        };
        let val = this.$store.state.noLoginGlobal || stateuser;
        return val;
      },
      set() {},
    },
  },
  created() {
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      let that = this;
      this.$get(this.$apis.getPcSeverice)
        .then((res) => {
          that.serviceList = res.data["pc.customer.service"];
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    // 图标选择
    cust_icon(index, type) {
      this.customerIndex = index;
      this.customerType = type;
      if (type == "customer.service.outside_url") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      }
    },
    // 类型切换
    typeTab(index, id) {
      this.serviceList[index]["customer.service.type"] = id;
    },
    // 图片选择器
    imgbrand(value) {
      this.serviceList[this.customerIndex]["" + this.customerType] =
        value[0].file_url;
      //console.log(this.serviceList);
    },
    // 链接选择器
    linkGet(value = "") {
      if (!value) {
        return;
      }
      this.serviceList[this.customerIndex]["customer.service.outside_url"] =
        value.link;
    },
    customerDown(index) {
      var options = JSON.parse(JSON.stringify(this.serviceList[index + 1]));
      this.$set(this.serviceList, index + 1, this.serviceList[index]);
      this.$set(this.serviceList, index, options);
    },
    customerUp(index) {
      var options = JSON.parse(JSON.stringify(this.serviceList[index - 1]));
      this.$set(this.serviceList, index - 1, this.serviceList[index]);
      this.$set(this.serviceList, index, options);
    },
    customerDel(index) {
      this.serviceList.splice(index, 1);
    },
    // 新增
    newAdd() {
      let that = this;
      let data = {
        "customer.service.type": 1,
        "customer.service.icon": "",
        "customer.service.title": "",
        "customer.service.outside_url": "",
        "customer.service.show": 1,
        "customer.service.qr_code": "",
        "customer.service.phone": "",
      };
      this.serviceList.push(data);
      this.$nextTick(() => {
        let scrollElem = that.$refs.scrollDiv;
        window.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
      });
    },
    // 使用默认图标
    tacitly_approve(index) {
      let type = this.serviceList[index]["customer.service.type"];
      let url = "";
      if (type == 1) {
        url = this.common_info["default.config.list"]["pc.default.wechat_icon"];
      } else if (type == 2) {
        url =
          this.common_info["default.config.list"]["pc.default.cell_phone_icon"];
      } else if (type == 3) {
        url = this.common_info["default.config.list"]["pc.default.qq_icon"];
      }
      this.serviceList[index]["customer.service.icon"] = url;
    },
    // 保存
    preserve() {
      let that = this;
      if (!this.serviceList.length) {
        this.$message({
          type: "warning",
          message: "客服不能为空",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      this.caijiLoading = true;
      let data = {
        "pc.customer.service": this.serviceList,
      };
      this.$put(this.$apis.getPcSeverice, data)
        .then((res) => {
          that.caijiLoading = false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading = false;
          that.common.message(that, err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.orderboxs {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
}
.management {
  flex: 1;
}
.management_basics {
  background: #fff;
  border-radius: 4px;
  position: relative;
  transition: all 0.2s ease-in-out;
  // margin-bottom: 2px;

  // padding-bottom: 32px;
  flex: 1;
}
.management_basics_body {
  // padding: 14px 20px;

  .service_title {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
}
.management_basics_form_item {
  margin-bottom: 24px;
  vertical-align: top;
  zoom: 1;
  .custom_content {
    flex-wrap: wrap;
    .typeSingle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #dddfe6;
      width: 128px;
      height: 38px;
      cursor: pointer;
      div:first-child {
        width: 70px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      .type_icon {
        margin-left: 12px;
        width: 23px;
        height: 23px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        i {
          font-size: 12px;
          color: #fff;
        }
      }
    }
    .typeSingle.active {
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #000d9a;
      font-size: 14px;
      font-weight: 400;
      color: #000d9a;
      line-height: 20px;
      div {
        font-size: 14px;
        font-weight: 400;
        color: #000d9a;
        line-height: 20px;
      }
    }
    .typeSingle:last-child {
      margin-right: 0;
    }
  }
  .icon_content {
    .icon_add {
      width: 52px;
      height: 52px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #dddfe6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        color: #dad9d9;
        font-size: 28px;
        font-weight: 100;
      }
    }
    .icon_img {
      width: 50px;
      height: 50px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .icon_toast {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
    .icon_moren {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 400;
      color: #000d9a;
      line-height: 18px;
      cursor: pointer;
      margin-top: 8px;
    }
  }
  .title_content {
    width: 356px;
    height: 38px;
    background: #ffffff;
    border-radius: 2px;
    // border: 1px solid #dddfe6;
    /deep/.el-input {
      width: 100%;
      height: 38px;
      line-height: 38px;
      .el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }
  }
  .show_content {
    display: flex;
    align-items: center;
    /deep/.el-switch__core {
      height: 24px;
      border-radius: 15px;
    }
    /deep/.el-switch__core:after {
      width: 20px;
      height: 20px;
    }
    /deep/.el-switch.is-checked .el-switch__core::after {
      margin-left: -22px;
    }
  }
}
.management_basics_form .management_basics_form_item:last-child {
  margin-bottom: 0;
}
.management_basics_form_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 106px;
}
.management_basics_form_text {
  color: var(--fontColor);
  font-size: 14px;
  cursor: pointer;
}
.management_foot {
  position: fixed;
  left: 240px;
  right: 0;
  bottom: 0;
  min-width: 980px;
  margin: 0 24px;
  height: auto;
  border-top: 1px solid #f1f1f1;
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.management_foot_body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.management-card-head {
  border-bottom: 1px solid #f2f2f2;
  padding: 8px 20px;
  line-height: 1;
}
.management-card-head p {
  display: inline-block;
  width: auto;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
.preview_name a img {
  margin-bottom: 4px;
  vertical-align: middle;
}
/* 客服设置 */
.wechatAppid .el-input {
  width: 300px;
}
.servicekong {
  width: 100%;
  height: 2px;
  background: #eaebf6;
}
.service_container {
  flex: 1;
  /* margin: 24px; */
  background-color: #fff;
  padding-top: 4px;
  .service_list {
    margin: 0 12px;
    .custom_single {
      margin: 0 auto;
      // margin-top: 20px;
      min-width: 600px;
      background: #f9fafc;
      border-radius: 3px;
      padding: 24px 0;
      position: relative;
      .handle {
        position: absolute;
        top: 12px;
        right: 0;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
    .addcustom {
      margin-top: 20px;
      width: 100px;
      height: 38px;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid #dddfe6;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #000d9a;
      line-height: 16px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      div {
        width: 14px;
        height: 14px;
        border: solid 1px #000d9a;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #000d9a;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }
}
.serive_form {
  padding: 20px;
}
.skeletonize {
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-right: 10px;
  border: 1px dashed #ededed;
  text-align: center;
  color: #dad9d9;
  float: left;
  position: relative;
  cursor: pointer;
  background: white;
}
.skeletonize i {
  font-size: 20px;
}
.skeletonize_toast {
  font-size: 12px;
  color: #999;
  padding: 4px 0;
  box-sizing: border-box;
  line-height: 1;
}
.service_show {
  width: 346px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  margin-left: 24px;
  transition: all 0.2s ease-in-out;
}
.service_show_body {
  padding: 20px;
}
.preview-customer {
  margin: auto;
  width: 66px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(218, 217, 231, 0.8);
  border-radius: 2px;
  text-align: center;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 12px 0;
  .preview_single {
    position: relative;
    margin: 0 8px;
    padding: 8px 0;
    .preview_top {
      width: 32px;
      height: 32px;
      background: #f4f5fc;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    img {
      width: 32px;
      height: 32px;
      text-align: center;
      object-fit: cover;
    }
    p {
      font-size: 12px;
      margin-top: 8px;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
    }
  }
}
.preview_title {
  line-height: 62px;
  font-size: 16px;
  color: #3b426b;
  position: relative;
}
b {
  font-weight: bolder;
}
.preview-customer p {
  font-size: 14px;
  color: #3b426b;
}
.preview-customer .bor_b::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 1px;
  background: #f7f7f7;
  display: block;
}
.preview_name a img {
  margin-bottom: 4px;
  vertical-align: middle;
}
.preview-customer .qr_code {
  padding-bottom: 14px;
  width: 142px;
  height: 142px;
  margin: 0 auto;
}
.preview-customer .chat_tel {
  padding: 10px 0 10px 0;
}
.preview-customer .phone_tel {
  color: #fb6638;
  font-weight: 500;
  flex: 1;
  font-size: 16px;
  padding-bottom: 10px;
  position: relative;
}
.service_third {
  margin-top: 20px;
}
.service_third .el-textarea {
  width: 560px;
  min-height: 220px;
}
.service_third .el-textarea .el-textarea__inner {
  min-height: 220px !important;
}
</style>